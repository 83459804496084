.ghori {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

.hehe {
  top: 0;
  position: sticky;
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
}

.signup-form {
  position: relative;
  width: 80%; /* Set the width relative to the viewport width */
  max-width: 500px; /* Set the maximum width */
  min-width: 300px; /* Set the minimum width */
  height: 700px; /* Automatically adjust the height based on content */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  padding-top: 0px;
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.signup-form h2 {
  color: #333;
  /*margin-bottom: 30px;*/
  /*border-bottom: 3px solid #5cd3b4;*/
  /*padding-bottom: 10px;*/
  text-align: center;
}

.signup-form form {
  color: #333;
}

.signup-form .form-group.row {
  margin-bottom: 20px;
}

.signup-form label {
  font-size: 16px;
  font-weight: bold;
}

.signup-form input[type="email"],
.signup-form input[type="text"],
.signup-form input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.signup-form .btn {
  font-size: 16px;
  font-weight: bold;
  background: #000;
  border: none;
  margin-top: 20px;
  width: 100%;
  color: white;
  padding: 10px;
}

.signup-form .btn:hover,
.signup-form .btn:focus {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

.signup-form a {
  color: #5cd3b4;
  text-decoration: none;
}

.signup-form a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
