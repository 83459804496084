@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-fill-mode: both;
}

body {
  height: 100vh;
  background-image: url("../assets/bg.jpg");
  background-size: 20%;
  background-attachment: fixed;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
  background-color: #000;
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 8%;
  color: #fff;
  transition: 0.4s;
}

.nav img {
  width: 100px;
  cursor: pointer;
  transition: 0.4s;
}

.nav img:hover {
  filter: invert(1);
}

#link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*font-weight: 0px;*/
  width: 80%;
}

.nav ul li {
  display: inline-block;
  list-style: none;
  margin-left: 80px;
}

.nav a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1em;
  transition: 0.4s;
}

.nav a:hover {
  color: rgb(207, 207, 207);
}

.toggle-menu {
  display: none;
}

.text {
  position: absolute;
  z-index: 10;
  left: 0;
  padding: 20px 8%;
  width: 50%;
  flex: 0 0 50%;
}

.header-image {
  width: 500px;
  height: 350px;
  object-fit: cover;
  position: absolute;
  right: 8%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}

.text a {
  display: inline-block;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #111;
  letter-spacing: 2px;
  transition: 0.2s;
}

.text a:hover {
  letter-spacing: 6px;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

#trips {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 20px 8%;
  scroll-snap-align: center;
}

h1 {
  font-size: 100px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
}

.tripscards {
  display: flex;
  align-items: center;
  padding-top: 40px;
  flex-wrap: wrap;
}

.card {
  position: relative;
  width: 300px;
  height: 500px;
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.4s;
  margin-bottom: 80px;
  margin-right: 50px;
  background-color: #fff;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#cover {
  width: 100%;
  height: 200px;
}

.card img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  object-fit: cover;
}

.details {
  padding: 5px 15px;
  padding-bottom: 20px;
}

.details p {
  font-style: italic;
  font-weight: lighter;
  padding-top: 10px;
}

.details .description {
  font-style: normal;
  padding-top: 0px;
  margin-bottom: 5px;
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background: linear-gradient(to right, black 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.register:hover {
  background-position: left bottom;
}

.register a {
  text-decoration: none;
  color: black;
  font-style: italic;
  font-size: 1.2em;
  line-height: 60px;
  width: 100%;
  transition: all 0.6s ease-out;
  text-align: center;
}

.register a:hover {
  color: white;
}

#team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0 0 0 0;
  scroll-snap-align: center;
}

#about-wrapper {
  position: relative;
}

#about-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.about-text {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  color: #fff;
  z-index: 3;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

#contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

#contact-info-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 8%;
  position: relative;
  width: 100%;
}

#whatsapp {
  width: auto;
  max-width: 200px;
  height: auto;
}

.form-control {
  font-weight: 400;
  width: 40%;
  display: block;
  padding: 10px;
  margin: 4px;
  direction: ltr;
}

.contact-message {
  width: 60%;
  padding-bottom: 50px;
}

#contact label {
  font-size: 1.2em;
}

#contact-button {
  min-width: 50%;
  width: auto;
  max-width: 300px;
  margin-top: 20px;
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  font-size: 1.2em;
}

#contact-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

input[type="text"] {
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  padding: 10px 0px 10px 0px;
}

#foot-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer {
  color: #fff;
}

.footer p {
  font-size: 0.9em;
}

.trademark {
  font-size: 0.8em;
  color: grey;
  padding-top: 20px;
}

@media (max-width: 992px) {
  .header-image {
    width: auto;
    height: 300px;
    /*width: 500px;
    /*height: 350p*/
    object-fit: cover;
    /*right: 2%;*/
    top: 58%;
    right: 0;
  }

  .header {
    height: 55vh;
    scroll-snap-align: start;
    flex-direction: column;
  }

  .toggle-menu {
    position: relative;
    display: flex;
    color: #fff;
    font-size: 2em;
    z-index: 5;
  }

  /*.nav #link-wrapper {
    display: none;
  }*/
  #link-wrapper {
    display: none;
    top: 0%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #000;
    color: rgb(255, 0, 0);
    z-index: 4;
    transition: top 0.5s;
  }

  #link-wrapper ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
  }

  #link-wrapper .not-needed {
    display: none;
  }

  #link-wrapper ul li {
    margin-left: 0px;
    padding: 4px;
    font-weight: 0.7rem;
  }

  .nav #link-wrapper.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    top: 25%;
  }

  .text h2 {
    font-size: 2em;
    color: black;
  }

  .text h3 {
    font-size: 1.8em;
    color: white;
  }

  .text p {
    font-size: 0.8em;
    color: black;
  }

  .text a {
    font-size: 0.8em;
  }

  #trips {
    min-height: 400px;
    top: 0%;
    position: relative;
    flex-direction: column;
    padding: 20px 8%;
  }

  .tripscards {
    display: flex;
    flex-direction: column;
  }

  .card {
    margin-bottom: 40px;
    margin-right: 0px;
  }

  #trips h1 {
    font-size: 2.2em;
  }

  .about-text {
    font-size: 1.6em;
  }

  #team {
    height: 40vh;
  }

  #about-wrapper {
    width: 100%;
    height: 100%;
  }

  #about-image {
    height: 100%;
    object-fit: cover;
  }

  #whatsapp {
    width: 80%;
    max-width: 180px;
  }

  #contact {
    /*height: 85vh;*/
    padding: 0px;
  }

  #contact-info-area {
    position: relative;
    height: 70%;
    padding: 20px 8%;
  }

  #contact-info-area h1 {
    font-size: 2.2em;
  }

  .form-control {
    width: 60%;
    display: block;
    padding: 6px 20px 6px 0px;
    margin: 4px;
    direction: ltr;
  }

  .contact-message {
    width: 80%;
    padding-bottom: 50px;
  }

  .footer p {
    font-size: 0.6em;
  }

  .trademark {
    font-size: 0.4em;
    padding-top: 20px;
  }
}

@media (max-width: 1200px) {
  .nav ul li {
    margin-left: 60px;
  }

  .nav a {
    font-size: 0.9em;
  }
}
